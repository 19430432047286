.top-banner {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 100%;
  
  

}
.mlogo {
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  display: none;
  cursor: pointer;
}
.regbtn {
  background-color: #ffffff;
  color: #108146;
  padding: 10px 20px;
  border: 2px solid #108146;
  border-radius: 5px;
  cursor: pointer;
}
.regbtn:hover {
  background-color: #108146;
  color: #ffffff;
}
.tab-text {
  padding: 2px;
  font-size: 16px;
  font-weight: 200;
  color: #433e3e;
  text-transform: capitalize !important;
}
.tescolo {
  background-color: #a0d3ba;
}
.tab-text:hover {
  background-color: #108146;
  color: #fff;
}
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropsub {
  position: absolute;
  display: inline-block;
  padding: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-transform: capitalize !important;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #ffffff !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding: 5px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-transform: capitalize !important;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #ffffff !important;
}
.dropdown:hover {
  color: #ffffff;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 460;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

.dropdown:hover .dropdown-content {
  opacity: 1;
  transform: translateY(0);
}
.backtomenu {
  display: none;
}
@media screen and (max-width: 550px) {
  .dropdown:hover .dropdown-content {
    position: relative;
  }
  .top-banner {
    position: relative;
  }
  .backtomenu {
    display: block;
    left: 1;
  }
  .mlogo {
    display: block;
    margin: 20px;
  }

  .dropdown-toggle.open + .dropdown-content {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 700px) {
  .dropdown:hover .dropdown-content {
    position: relative;
    
  }
  .top-banner {
    position: relative;
 
  }
  .dropdown-toggle.open + .dropdown-content {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
 
  .backtomenu {
    display: block;
    left: 1;
  }
}

@media screen and (max-width: 1023px) {
  .dropdown:hover .dropdown-content {
    position: relative;
  }
.dropdown-toggle.open + .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);
}
.mlogo{
  display: block;
  margin: 20px;
}
.top-banner {
  position: relative;
}
.backtomenu {
  display: block;
  left: 1;
}

}
/*
@media screen and (max-width: 1366px) {
  .dropdown:hover .dropdown-content {
    position: relative;

  }
  .mlogo{
    display: block;
    margin: 20px;
  }
  .top-banner {
    position: relative;
  }
.dropdown-toggle.open + .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);

}
.backtomenu {
  display: block;
  left: 1;
}
}*/
.dropdown-content a {
  color: rgb(37, 39, 37);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  left: 10px;
}

.dropdown-content a:hover {
  background-color: #d7dbd0;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  background-color: #cfded0;
}
.dropdown:not(hover) .dropdown-content {
  display: none;
}

.ch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
}

.ch__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
  height: 100px;
}
.ch__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ch__navbar-links_logo {
  margin-right: 2rem;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.ch__navbar-links_logo img {
  width: 69.56px;
  height: 60.02px;
}
.ch__navbar-links_container {
  display: flex;
  flex-direction: row;
}
.ch__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ch__navbar-links_container p,
.ch__navbar-sign p,
.ch__navbar-menu_container p {
  font-family: "Leto", sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  padding: 1rem;
}
.ch__navbar-sign button,
.ch__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #0f0e0e;
  background: #d8dec7;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.ch__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: fixed;
  left: 0;
  top: 1rem;
  z-index: 100;
  
}


.ch__navbar-logo {
  margin-right: 1rem;
  display: none;
  position: fixed;
  right: 15px;
  top: 2rem;
  z-index: 100;
  
}

.ch__navbar-menu:hover {
  background-color: #fff;
}

/* menu btn */
.ch__navbar-menu_container li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  position: relative;
  top: 1px;
  right: 0;
  margin-top: 1rem;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(20, 19, 19, 0.2);
  z-index: 450;
}

.ch__navbar-menu_container li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: rgb(246, 248, 244); /* Change the color as needed */
}

.ch__navbar-menu_container p {
  margin: 1rem 0;
}
.ch__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .ch__navbar-links_container {
    display: none;
  }
  .ch__navbar-menu {
    display: flex;
  }
  .menuframe {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .ch__navbar-links_container {
    display: none;
  }
  .ch__navbar-menu {
    display: flex;
  }
  .menuframe {
    display: none;
  }
}
/*
@media screen and (max-width: 1366px) {
  .ch__navbar-links_container {
    display: none;
  }
  .ch__navbar-menu {
    display: flex;
  }
  .ch__navbar-logo {
    display: flex;
  }
  .menuframe {
    display: none;
  }
}*/

@media screen and (max-width: 700px) {
  .ch__navbar {
    padding: 2rem 4rem;
  }
  .menuframe {
    display: none;
  }
  .ch__navbar-menu {
    display: flex;
  }
  .ch__navbar-logo {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .ch__navbar {
    padding: 4rem;
  }
  .ch__navbar-menu {
    display: flex;
  }
  .ch__navbar-logo {
    display: flex;
  }
  .ch__navbar-sign {
    display: none;
  }
  .menuframe {
    display: none;
  }
  .ch__navbar-menu_container {
    top: 20px;
  }
  .ch__navbar-menu_container-links-sign {
    display: block;
  }
}





.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 340;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 3, 3, 0.847);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 2px 16px;
  border: 1px solid #888;
  height: 100%;
  width: 50%;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 1.4s;
  overflow-y: auto;
}

@media screen and (max-width: 700px) {
  .modal-content {
    width: 80%;
    height: 98%;
    margin-bottom: 3px;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.close {
  color: rgb(241, 7, 7);
  float: right;
  font-size: 80px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: white;
  color: white;
  height: 60px;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 1%;
  height: 1%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.modal button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin: 20px 0;
  background: red;
  background: #41b5cb;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700; }

.event-open {
top: 0; }


/****** Recent Sermon Tabs******/
.tabs-section {
  /*background: var(--color-grey);*/
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  padding: 1rem;
  margin-top: 3rem;
  scroll-behavior: smooth;
  box-shadow: 0 0 10px rgb(223, 238, 208);
  border-radius: 10px;
}

.tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tabs-title {
  width: 20%;
  margin-right: 1rem;
  overflow-y: auto;
  max-height: 480px;

}

.tabs-contents {
  width: 70%;
  padding: 1rem;
  background: white;
  max-height: 480px;
  overflow-y: auto;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
}

.tab {
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  border: 1px solid var(--color-white);
  border-left: 3px solid orangered;
  background: #d9d8d4;
  cursor: pointer;
  display: block;
  text-align: left;
}

.active-tab {
  border-left: 5px solid var(--color-purple);
  color: #fff;
  position: relative;
  background: #108146 ;
  border-left: #108146 ;
}

.active-tab::after {
  position: absolute;
  top: 15px;
  right: -5px;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-purple);
  transform: rotate(45deg);
}

.contents {
  display: none;
}

.active-contents {
  display: block;
  animation: slide-right 0.5s ease 1;
}

@keyframes slide-right {
  0% {
    transform: translateX(-10rem);
  }
  100% {
    transform: translateX(0);
  }
}

article {
  padding: 1rem 0;
}

.article-title{
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px
}

/* Custom Scrollbar */
.tabs-contents::-webkit-scrollbar {
  width: 12px;
}

.tabs-contents::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tabs-contents::-webkit-scrollbar-thumb {
  background: #888;
}

.tabs-contents::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scroll Indicator */
.scroll-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 2rem;
  color: grey;
  opacity: 0.7;
  pointer-events: none;
  animation: bounce 2s infinite;
}



/**** bullets*****/
.custom-bullets {
  list-style-type: none;
  padding-left: 0;
 
}

.custom-bullets li {
  position: relative;
  padding-left: 20px; 
  
}

.custom-bullets li::before {
  position: absolute;
  left: 0;
  color: #333; 
  font-size: 20px; 
  line-height: 1; 
}
.custom-bullets li a:hover {
  color:#f46d6d;
  text-decoration: underline;
}